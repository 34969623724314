.indicator {
  display: inline-block;
  padding: 0.5em 1em;
  text-align: center;
  width: 5em;
  border-radius: 4px;
  color: black;
  font-weight: bold;
}

.dial-input {
  width: 10em;

  input {
    text-align: center;
  }
}

i.fas {
  padding: 10px;
}

.stack-status {
  text-align: right;
}
